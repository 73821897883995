<template>
  <v-card>
    <v-card-title>
      {{ $t("manageFacilityFiles.manage_files_for") }}
      {{ system.organisation }} - {{ system.location }}{{ (system.department) ? " - " : "" }}{{ system.department }}</v-card-title
    >
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      :label="$t('manageFacilityFiles.search_files')"
      single-line
      hide-details
      class="col-12"
    ></v-text-field>
    <v-data-table
      :search="search"
      :items="filesToShow"
      :headers="headers"
      :loading="loading"
    >
      <template v-slot:item="row">
        <tr>
          <td v-if="row.item.isDir" class="pt-3 pb-3" style="font-size: 95%">
            <a style="color: black" @click="listSubFiles(row.item.name)">
              <v-icon class="pr-1 pb-1 mt-1">mdi-folder</v-icon>
              {{ row.item.name }}
            </a>
          </td>
          <td
            v-if="row.item.isDir == false"
            class="pl-4 pt-1"
            style="font-size: 95%"
          >
            <p>
              <v-icon class="pr-1 pb-1 mt-1">mdi-file-plus</v-icon>
              {{ row.item.name }}
            </p>
          </td>
          <td>
            <v-layout justify-end>
              <v-icon class="pr-1 pb-1 mt-1" @click="deleteFile(row.item.name)"
                >mdi-delete</v-icon
              >
              <v-icon
                class="pr-1 pb-1 mt-1"
                @click="showChangeNameDialog(row.item.name)"
                >mdi-pencil</v-icon
              >
            </v-layout>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-btn
      class="primary ml-2 mb-2"
      text
      v-if="path.length > 0"
      @click="listPreviousFiles()"
    >
      {{ $t("manageFacilityFiles.previous_file") }}</v-btn
    >
    <v-btn class="primary ml-2 mb-2" text @click="uploadFilesDialog = true">
      {{ $t("manageFacilityFiles.upload_files") }}
    </v-btn>

    <v-dialog v-model="uploadFilesDialog" persistent max-width="550">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("manageFacilityFiles.upload_files") }}
        </v-card-title>
        <v-file-input
          accept="image/png, image/jpeg, image/jpg, video/mp4"
          prepend-icon="mdi-file-multiple"
          :label="$t('manageFacilityFiles.click_here_to_upload')"
          v-model="uploadedFiles"
          show-size
          multiple
          counter
          outlined
          dense
          :loading="loadingBarDialog"
          loader-height="6"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip close @click:close="deleteChip(index)">
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            dark
            @click="submitFiles()"
            v-if="uploadedFiles.length >= 1"
          >
            {{ $t("manageFacilityFiles.submit") }}
          </v-btn>
          <v-btn color="blue darken-1" dark @click="uploadFilesDialog = false">
            {{ $t("manageFacilityFiles.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadedSuccessfully" persistent max-width="550">
      <template> </template>
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("manageFacilityFiles.files_successfully_uploaded") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="uploadedSuccessfully = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showServerErrorDialog" persistent max-width="560px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t("manageFacilityFiles.server_error")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showServerErrorDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showFilesDeletedDialog" persistent max-width="560px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t("manageFacilityFiles.file_is_deleted")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showFilesDeletedDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showNoNameErrorDialog" persistent max-width="550">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("manageFacilityFiles.insert_name") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showNoNameErrorDialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fileNewNameDialog" persistent max-width="550">
      <v-card>
        <v-card-title>
          {{ $t("manageFacilityFiles.edit_file") }}:
          <v-text-field
            v-model="fileNewName"
            type="text"
            append-icon="mdi-pencil"
            class="ml-2"
            :label="$t('manageFacilityFiles.file_new_name')"
          />
        </v-card-title>
        <v-btn class="primary ml-1 mb-1" text @click="editFileName">
          {{ $t("manageFacilityFiles.save") }}
        </v-btn>
        <v-btn
          class="primary ml-2 mb-1"
          text
          @click="fileNewNameDialog = false"
        >
          {{ $t("manageFacilityFiles.cancel") }}
        </v-btn>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
var path = require("path");
import { ILLI_API_SERVER_URL } from "../config";
export default {
  mounted() {
    if (this.system?.id === undefined) {
      return this.$router.push("systems");
    }
    this.loadFiles()
  },
  data() {
    return {
      path: [],
      search: "",
      loading: false,
      uploadFilesDialog: false,
      loadingBarDialog: false,
      uploadedSuccessfully: false,
      showServerErrorDialog: false,
      showFilesDeletedDialog: false,
      showNoNameErrorDialog: false,
      fileNewNameDialog: false,
      files: [],
      filesToShow: [],
      uploadedFiles: [],
      headers: [
        { text: "Titel", value: "name" },
        { text: "", value: "actions", sortable: false },
      ],
      fileNewName: "",
      fileName: "",
    };
  },
  computed: {
    ...mapGetters({
      system: "system/system",
      user: "auth/user",
    }),
  },

  methods: {
    loadFiles() {
      this.loading = true;
      axios.get(`${ILLI_API_SERVER_URL}/systems/${this.system.id}/files`)
      .then(({data:{list}}) => {
        this.files = list.map(file => {
          if(file.Name === "initiate") return;
          return {
            name: file.Name,
            path: file.Path,
            isDir: file.IsDir,
          }
        }).filter(file => file);
        this.listFiles()
      });
    },
    listFiles() {
      this.loading = true;
      this.filesToShow = this.files.filter(file => {
        return this.solveDirName(file.path) === this.path.join('/')
      })
      this.loading = false;
    },
    listSubFiles(item) {
      this.path.push(item)
      this.listFiles()
    },
    listPreviousFiles() {
      this.path.pop()
      this.listFiles()
    },

    submitFiles() {
      this.loadingBarDialog = true;
      let formData = new FormData();
      this.uploadedFiles.forEach(file => {
        formData.append("files", file);
      });
      formData.append("path", this.path.join("/"));

      axios.post(`${ILLI_API_SERVER_URL}/systems/${this.system.id}/files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          this.loadFiles()
          this.path = [];
          this.uploadedFiles = [];
          this.uploadFilesDialog = false;
          this.loadingBarDialog = false;
          this.uploadedSuccessfully = true;
        }
      })
      .catch(() => {
        this.loadingBarDialog = false;
        this.showServerErrorDialog = true;
      });
    },

    deleteChip(index) {
      this.uploadedFiles.splice(index, 1);
    },
    deleteFile(item) {
      axios.delete(`${ILLI_API_SERVER_URL}/systems/${this.system.id}/files/${this.path.concat(item).join('/')}`)
      .then((response) => {
        if (response.status == 200) {
          this.showFilesDeletedDialog = true;
          this.loadFiles()
        }
      })
      .catch(() => {
        this.showServerErrorDialog = true;
      });
    },
    editFileName() { 
      if (this.fileNewName == "") {
        this.showNoNameErrorDialog = true;
      } else {
        axios.patch(`${ILLI_API_SERVER_URL}/systems/${this.system.id}/files/${this.path.concat(this.fileName).join('/')}`, {fileName:this.fileNewName} )
        .then((response) => {
          if (response.status == 200) {
            this.loadFiles();
            this.fileNewNameDialog = false;
            this.fileNewName = "";
            this.fileName = "";
          }
        })
        .catch(() => {
          this.showServerErrorDialog = true;
        });
      }
    },
    showChangeNameDialog(fileName) {
      this.fileName = fileName;
      this.fileNewName = fileName;
      this.fileNewNameDialog = true;
    },
    solveDirName(filePath) {
      const dirName = path.dirname(filePath)
      if (dirName === '.') return ""
      else return dirName
    }
  },
};
</script>